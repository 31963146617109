<template>
    <div class="max-box">
        <div class="login-box">
            <div class="logo-box">
                <img src="../assets/logo/loginLogo2.png" alt=""/>
            </div>
            <div class="login-type">手机号登录</div>
            <a-form-model ref="ruleForm" :model="loginForm" :rules="rules">
                <a-form-model-item prop="phone">
                    <a-input placeholder="手机号" v-model="loginForm.phone">
                        <a-icon type="phone" slot="prefix"/>
                    </a-input>
                </a-form-model-item>
                <a-form-model-item prop="password">
                    <a-input-password placeholder="密码" v-model="loginForm.password">
                        <a-icon type="lock" slot="prefix"/>
                    </a-input-password>
                </a-form-model-item>
            </a-form-model>
            <div class="btn-box">
                <a-button type="primary" class="btn" @click="login">登录</a-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {setToken} from "@/utils/auth.js";
    import {loginApi} from "@/api/login.js";

    import crypto from "crypto";

    export default {
        data() {
            return {
                loginForm: {
                    phone: "",
                    password: "",
                },
                rules: {
                    phone: [{required: true, message: "手机号不能为空", trigger: "blur"}],
                    password: [
                        {required: true, message: "密码不能为空", trigger: "blur"},
                    ],
                },
            };
        },
        methods: {
            login() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch("User/Login", {
                                telPhone: this.loginForm.phone,
                                password: this.loginForm.password,
                            })
                            .then(() => {
                                this.$router.push("/");
                            })
                            .catch((err) => {
                            });
                    } else {
                        return false;
                    }
                });
            },
            toRegister() {
                this.$router.push("/register/1");
            },
        },
        mounted() {
            console.log(this.$store);
        },
    };
</script>
<style lang="less" scoped>
    .max-box {
        width: 100%;
        height: 100%;
        display: flex;
        // overflow: hidden;
        .flexbox {
            flex: 1;
            width: 50%;
            height: 100%;
        }

        .left-img-box {
            .ant-carousel {
                width: 100%;
                height: 100%;

                .slick-slider {
                    height: 100%;
                }

                .img-box {
                    width: 100%;
                    height: 100vh;
                    // background: #1e5cc3;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .right-login-box {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .login-box {
            background: #fff;
            width: 414px;
            height: 414px;
            padding: 32px 32px 40px 32px;
            box-sizing: border-box;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
            border: 1px solid rgba(37, 43, 58, 0.1);
            border-radius: 6px;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .logo-box {
                width: 100%;
                height: 50px;
                // background: #012057;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 144px;
                    height: 40;
                }
            }

            .login-type {
                font-weight: 700;
                color: #048fff;
                font-size: 14px;
                text-align: center;
                height: 61px;
                line-height: 61px;
            }

            .register-box {
                color: #00000073;
                margin-top: -8px;
                margin-bottom: 24px;
                display: flex;
                justify-content: space-between;

                span {
                    cursor: pointer;

                    &:hover {
                        color: #40a9ff;
                    }
                }
            }

            .btn-box {
                margin-top: 20px;

                .btn {
                    width: 100%;
                    height: 40px;
                }
            }

            /deep/ .ant-input {
                height: 40px !important;
            }

            .ant-input-prefix {
                i {
                    color: #dfdfe2;
                }
            }
        }

        // background: #048fff;
    }
</style>
